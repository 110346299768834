<div class="onDemandFooter bg-navy" *ngIf="ondemandLinks || ondemandHeader">
  <div class="logo-center">
    <a href="{{ onDemandUrl }}"><img alt="LabcorpOnDemand" class="OnDemandfooter-logo" src="assets/images/OnDemandSecondaryLogo.svg" /></a>
  </div>
  <div class="text-end">
    <div>© 2018-{{ appYear }} Laboratory Corporation of America® Holdings.</div>
    <div>All Rights Reserved.</div>
  </div>
</div>
<div *ngIf="showFooterContent && !ondemandLinks && !portalLinks" class="bg-white pt-4 pt-md-6">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-3">
        <a href="https://www.labcorp.com/" target="_blank"
          ><img alt="Labcorp" class="footer-logo mb-6" src="assets/images/Labcorp_Logo.svg"
        /></a>
      </div>
      <div class="col-md-9">
        <!-- Desktop View -->
        <div class="row d-none d-md-flex mb-3">
          <div class="col-md-4">
            <h4 class="mt-0 text-black">Guest Services</h4>
            <ul class="list-unstyled">
              <li class="mb-3">
                <a [href]="apptSchedulingUrl" target="_blank">Make an Appointment</a>
              </li>
              <li class="mb-3">
                <a routerLink="invoices/find/#heading">Billing and Insurance</a>
              </li>
              <li class="mb-3">
                <a [href]="patientLitholinkUrl" target="_blank">Order an At-home 24 Hour Urine Kit </a>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4 class="mt-0 text-black">Labcorp Patient</h4>
            <ul class="list-unstyled">
              <li class="mb-3">
                <a href="#" (click)="signIn()">Sign In</a>
              </li>
              <li class="mb-3">
                <a routerLink="/account/registration/">Create an Account</a>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4 class="mt-0 text-black">Get Help</h4>
            <ul class="list-unstyled">
              <li class="mb-3">
                <a [href]="helpCenterUrl" target="_blank">Help Center</a>
              </li>
              <li class="mb-3">
                <a href="https://www.labcorp.com/help/contact-info-for-patient/patient-portal-inquiry" target="_blank">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Mobile View -->
        <div class="row d-md-none mb-3">
          <div class="col-md-12">
            <ul class="custom-footer-accordion">
              <li>
                <a class="d-flex align-items-center" [routerLink]="[]" [class.active]="active[0]" (click)="toggleSection(0)">
                  <span class="flex-fill">Guest Services</span>
                  <i class="material-icons-round">{{ active[0] ? 'expand_less' : 'expand_more' }}</i>
                </a>
                <ul class="content pt-0" [class.collapse]="!active[0]">
                  <li>
                    <a [href]="apptSchedulingUrl" class="small" target="_blank">Make an Appointment</a>
                  </li>
                  <li>
                    <a routerLink="invoices/find/#heading" class="small">Billing and Insurance</a>
                  </li>
                  <li>
                    <a [href]="patientLitholinkUrl" class="small" target="_blank">Order an At-home 24 Hour Urine Kit </a>
                  </li>
                </ul>
              </li>
              <li>
                <a class="d-flex align-items-center" [routerLink]="[]" [class.active]="active[1]" (click)="toggleSection(1)">
                  <span class="flex-fill">Labcorp Patient</span>
                  <i class="material-icons-round">{{ active[1] ? 'expand_less' : 'expand_more' }}</i>
                </a>
                <ul class="content pt-0" [class.collapse]="!active[1]">
                  <li>
                    <a (click)="signIn()" class="small">Sign In</a>
                  </li>
                  <li>
                    <a routerLink="/account/registration/" class="small">Create an Account</a>
                  </li>
                </ul>
              </li>
              <li>
                <a class="d-flex align-items-center" [routerLink]="[]" [class.active]="active[2]" (click)="toggleSection(2)">
                  <span class="flex-fill">Get Help</span>
                  <i class="material-icons-round">{{ active[2] ? 'expand_less' : 'expand_more' }}</i>
                </a>
                <ul class="content pt-0" [class.collapse]="!active[2]">
                  <li>
                    <a [href]="helpCenterUrl" class="small" target="_blank">Help Center</a>
                  </li>
                  <li>
                    <a href="https://www.labcorp.com/help/contact-info-for-patient/patient-portal-inquiry" class="small" target="_blank"
                      >Contact Us</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-navy py-4" *ngIf="!ondemandLinks && !ondemandHeader && !portalLinks">
  <div class="container-xxl text-white">
    <div class="col-12">
      <div class="d-flex flex-wrap justify-content-center text-center">
        <ul class="list-inline mb-5">
          <li class="list-inline-item mx-2 mb-2">
            <a href="https://www.labcorp.com/terms-and-conditions" target="_blank" class="text-white">Terms and Conditions</a>
          </li>
          <li class="list-inline-item mx-2 mb-2">•</li>
          <li class="list-inline-item mx-2 mb-2">
            <a href="https://www.labcorp.com/hipaa-privacy" target="_blank" class="text-white">Privacy Policy</a>
          </li>
          <li class="list-inline-item mx-2 mb-2">•</li>
          <li class="list-inline-item mx-2 mb-2">
            <a [href]="patientUserAgreement" target="_blank" class="text-white">Patient Portal User Agreement</a>
          </li>
          <li class="list-inline-item mx-2 mb-2">•</li>
          <li class="list-inline-item mx-2 mb-2">
            <a class="ot-sdk-show-settings text-white" tabindex="0" onkeydown="if (event.key === 'Enter') this.click()"
              >Do Not Sell or Share My Personal Information</a
            >
          </li>
          <li class="list-inline-item mx-2 mb-2">•</li>
          <li class="list-inline-item mx-2 mb-2">
            <a href="https://www.labcorp.com/patients/bill-pay/payment-options/no-surprises-act" target="_blank" class="text-white"
              >No Surprise Act</a
            >
          </li>
          <li class="list-inline-item mx-2 mb-2">•</li>
          <li class="list-inline-item mx-2 mb-2">
            <a href="https://www.labcorp.com/labcorp-nondiscrimination-notice" target="_blank" class="text-white"
              >Notice of Nondiscrimination</a
            >
          </li>
        </ul>
      </div>
      <div class="d-flex flex-wrap justify-content-center text-center">
        <small> Labcorp Patient™ © {{ appYear }} Laboratory Corporation of America® Holdings. All Rights Reserved. </small>
      </div>
    </div>
  </div>
</div>
<footer *ngIf="portalLinks" class="bg-primary text-light">
  <patient-ui-portal-footer></patient-ui-portal-footer>
</footer>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  EventCode,
  IBadDebtMetric,
  IInsuranceProvider,
  IRequestError,
  IValidationErrors,
  InsuranceProvider,
  NOT_LISTED,
  NO_INSURANCE,
  Payment,
  QueryAccessionedOrderResponse,
  UpdateInsuranceFailedResponse,
} from '@patient-ui/shared/models';

import * as invoiceActions from './invoice.actions';
import { InvoiceState } from './invoice.reducer';
import { InvoiceService } from './invoice.service';
import { MetricActions } from '../metric/metric.actions';
import { MetricState } from '../metric/metric.reducer';

@Injectable()
export class InvoiceEffects {
  findInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.findInvoice),
      mergeMap((action) =>
        this.invoiceService.getInvoice(action.payload, action.campaign).pipe(
          map((response) =>
            response === null
              ? invoiceActions.findInvoiceFailure({
                  payload: RequestStatus.SuccessButEmptyResponse,
                })
              : invoiceActions.findInvoiceSuccess({ payload: response })
          ),
          catchError((error) => {
            switch (error.status) {
              case 409:
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: true })
                );
                return of(
                  invoiceActions.findInvoiceFailure({
                    payload: RequestStatus.Failure,
                  })
                );
              case 404 || 400: {
                const requestStatus =
                  error.status === 404 || error.status === 400
                    ? RequestStatus.SuccessButEmptyResponse
                    : RequestStatus.Failure;
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: false })
                );
                return of(
                  invoiceActions.findInvoiceFailure({ payload: requestStatus })
                );
              }
              default:
                this.store.dispatch(
                  invoiceActions.duplicateInvoice({ payload: false })
                );
                return of(
                  invoiceActions.findInvoiceFailure({
                    payload: RequestStatus.Failure,
                  })
                );
            }
          })
        )
      )
    )
  );

  generateReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.generateReceipt),
      exhaustMap((action) =>
        this.invoiceService.generateReceipt(action.confirmationNumber).pipe(
          map((response) =>
            invoiceActions.generateReceiptSuccess({
              response: response,
            })
          ),
          catchError((error) => {
            console.error('error', error);
            switch (error.status) {
              case 400:
                return of(
                  invoiceActions.generateReceiptFailure({
                    response: {
                      successful: false,
                      reasonCode: 400,
                      reasonDescription: `Invalid confirmation number.`,
                    },
                  })
                );
              default:
                return of(
                  invoiceActions.generateReceiptFailure({
                    response: {
                      successful: false,
                      reasonCode: 500,
                      reasonDescription: `Error sending email.`,
                    },
                  })
                );
            }
          })
        )
      )
    )
  );

  getInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getInvoice),
      exhaustMap((action) => {
        this.store.dispatch(invoiceActions.clearInvoiceSearchResult());
        return this.invoiceService
          .getInvoice(action.payload, action.campaign)
          .pipe(
            map((response) =>
              response === null
                ? invoiceActions.getInvoiceFailure({
                    payload: RequestStatus.SuccessButEmptyResponse,
                  })
                : invoiceActions.getInvoiceSuccess({ payload: response })
            ),
            catchError((error) => {
              switch (error.status) {
                case 409:
                  this.store.dispatch(
                    invoiceActions.duplicateInvoice({ payload: true })
                  );
                  return of(
                    invoiceActions.getInvoiceFailure({
                      payload: RequestStatus.Failure,
                    })
                  );
                case 404 || 400: {
                  const requestStatus =
                    error.status === 404 || error.status === 400
                      ? RequestStatus.SuccessButEmptyResponse
                      : RequestStatus.Failure;
                  this.store.dispatch(
                    invoiceActions.duplicateInvoice({ payload: false })
                  );
                  return of(
                    invoiceActions.getInvoiceFailure({ payload: requestStatus })
                  );
                }
                default:
                  this.store.dispatch(
                    invoiceActions.duplicateInvoice({ payload: false })
                  );
                  return of(
                    invoiceActions.getInvoiceFailure({
                      payload: RequestStatus.Failure,
                    })
                  );
              }
            })
          );
      })
    )
  );

  getPatientInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getPatientInvoice),
      exhaustMap((action) => {
        this.store.dispatch(invoiceActions.clearInvoiceSearchResult());
        return this.invoiceService.getPatientInvoice(action.payload).pipe(
          map((response) =>
            invoiceActions.getPatientInvoiceSuccess({
              payload: response,
            })
          ),
          catchError((error) => {
            console.error('error', error);
            return of(invoiceActions.getPatientInvoiceFailure());
          })
        );
      })
    )
  );

  getInvoicePdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getInvoicePdf),
      exhaustMap((action) =>
        this.invoiceService.getInvoicePdf(action.payload).pipe(
          map((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            return invoiceActions.getInvoicePdfSuccess({ payload: blob });
          }),
          catchError(() => of(invoiceActions.getInvoicePdfFailure()))
        )
      )
    )
  );

  getInvoiceSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getInvoiceSummary),
      exhaustMap(() => {
        const allPatientSummary = {
          id: 0,
          displayName: `All Patients`,
        };
        return this.invoiceService.getInvoiceSummary(allPatientSummary).pipe(
          map((invoiceSummary) => {
            let allPayments: Payment[] = [];
            invoiceSummary.paymentHistory?.forEach((paymentHistoryItem) => {
              paymentHistoryItem.payments.forEach((payment) => {
                const paymentItem: Payment = new Payment();
                paymentItem.pid = paymentHistoryItem.pid ?? 0;
                paymentItem.paymentDisplayName =
                  paymentHistoryItem.patientFirstName +
                  ' ' +
                  paymentHistoryItem.patientLastName;
                paymentItem.lcaInvoiceNumber =
                  paymentHistoryItem.lcaInvoiceNumber ?? '';
                paymentItem.dateOfService = paymentHistoryItem.dateOfService;
                paymentItem.paymentStatus = payment.paymentStatus;
                paymentItem.transactionId = payment.transactionId;
                paymentItem.encryptedTransactionId =
                  payment.encryptedTransactionId;
                paymentItem.amountPaid = payment.amountPaid;
                paymentItem.paymentDate = payment.paymentDate;
                paymentItem.scheduledPaymentId = payment.scheduledPaymentId;
                paymentItem.scheduledPaymentInvoiceId =
                  payment.scheduledPaymentInvoiceId;
                paymentItem.documentKey = payment.documentKey;
                paymentItem.dateOfServiceValue = new Date(
                  paymentHistoryItem.dateOfService
                );
                const dateComponents = payment.paymentDate.split(`/`);
                if (dateComponents.length === 3) {
                  paymentItem.paymentDateValue = new Date(
                    parseInt(dateComponents[2], 10),
                    parseInt(dateComponents[0], 10) - 1,
                    parseInt(dateComponents[1], 10)
                  );
                } else {
                  /*
                   * Something happened that has prevented us from parsing a data from the EPS
                   * response, I'm assuming this bad data is historical, and won't be on the most
                   * recent payments, which is what we want to show the user first anyways, so
                   * I'm setting the "date" on those to 00:00:00.000 on January 1, 1970 GMT to
                   * hide them at the end of the payment history.
                   */
                  console.error(
                    `Error creating a date value from ${paymentItem.paymentDate}`
                  );
                  paymentItem.paymentDateValue = new Date(0);
                }
                allPayments.push(paymentItem);
              });
            });
            allPayments = allPayments.sort((a, b) => {
              if (!b.paymentDateValue) {
                console.error(
                  `No 'b' payment date value in ${JSON.stringify(b)}`
                );
              }
              if (!a.paymentDateValue) {
                console.error(
                  `No 'a' payment date value in ${JSON.stringify(a)}`
                );
              }
              b.paymentDateValue = b.paymentDateValue || new Date(0);
              a.paymentDateValue = a.paymentDateValue || new Date(0);
              return (
                b.paymentDateValue.getTime() - a.paymentDateValue.getTime()
              );
            }); //sort desc by date
            this.store.dispatch(
              invoiceActions.populateInvoicePaymentsAll({
                payload: allPayments,
              })
            );
            this.store.dispatch(
              invoiceActions.populateInvoicePaymentsFiltered({
                payload: allPayments,
              })
            );
            return invoiceActions.getInvoiceSummarySuccess({
              payload: invoiceSummary,
            });
          }),
          catchError(() => of(invoiceActions.getInvoiceSummaryFailure()))
        );
      })
    )
  );

  createPaymentPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.createPaymentPlan),
      exhaustMap((action) =>
        this.invoiceService.createPaymentPlan(action.payload).pipe(
          map((response) =>
            invoiceActions.createPaymentPlanSuccess({ response: response })
          ),
          catchError((_error) => of(invoiceActions.createPaymentPlanFailure()))
        )
      )
    )
  );

  updateInsurance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.updateInsurance),
      exhaustMap((action) =>
        this.invoiceService
          .updateInsurance(
            action.payload.invoiceOrSpecimenNumber,
            action.payload.zipCode,
            action.payload.data,
            action.campaign
          )
          .pipe(
            map(() => invoiceActions.updateInsuranceSuccess()),
            catchError((error) => {
              if (
                error.status === 404 ||
                error.status === 400 ||
                error.status === 500
              ) {
                if (error.error && error.error.code === 'EV failed') {
                  return of(
                    invoiceActions.updateInsuranceEVFailure({
                      payload: error.error as UpdateInsuranceFailedResponse,
                    })
                  );
                }
              }
              return of(invoiceActions.updateInsuranceFailure());
            })
          )
      )
    )
  );

  queryForAccessionedOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.queryForAccessionedOrder),
      exhaustMap((action) =>
        this.invoiceService.queryForAccessionedOrder(action.queryRequest).pipe(
          map((res: QueryAccessionedOrderResponse) =>
            invoiceActions.queryForAccessionedOrderSuccess({ response: res })
          ),
          catchError((error) =>
            of(
              invoiceActions.queryForAccessionedOrderFailure({
                error: error as IRequestError,
              })
            )
          )
        )
      )
    )
  );

  getInsuranceProviderList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getProviderList),
      exhaustMap(() =>
        this.invoiceService.getInsuranceProviderList().pipe(
          map((providers) => {
            providers.map(
              (provider: IInsuranceProvider) => new InsuranceProvider(provider)
            );
            providers.sort((a, b) =>
              a.payerName.toLocaleLowerCase('en-US') >
              b.payerName.toLocaleLowerCase('en-US')
                ? 1
                : -1
            );
            const noInsuranceProvider = providers.find(
              (provider) =>
                provider.payerName
                  .toLocaleLowerCase('en-US')
                  .search(NO_INSURANCE) > -1
            );
            const notListedProvider = providers.find(
              (provider) =>
                provider.payerName
                  .toLocaleLowerCase('en-US')
                  .search(NOT_LISTED) > -1
            );
            if (noInsuranceProvider) {
              providers.unshift(noInsuranceProvider);
            }
            if (notListedProvider) {
              providers.unshift(notListedProvider);
            }
            providers = providers.filter(
              (provider) => provider !== noInsuranceProvider
            );
            const uniqueProividers = new Set(providers); // Removes the duplicates created by unshift
            const setToProviderArray = [...uniqueProividers]; // Go back to array
            return invoiceActions.getProviderListSuccess({
              payload: setToProviderArray,
            });
          }),
          catchError(() => of(invoiceActions.getProviderListFailure()))
        )
      )
    )
  );

  getProcessingInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getProcessingInvoices),
      exhaustMap(() =>
        this.invoiceService.getProcessingInvoices().pipe(
          map((response) =>
            invoiceActions.getProcessingInvoicesSuccess({
              payload: response,
            })
          ),
          catchError(() => of(invoiceActions.getProcessingInvoicesFailure()))
        )
      )
    )
  );

  getPaymentPlanAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getPaymentPlanAuthorization),
      exhaustMap((action) =>
        this.invoiceService
          .authorizePaymentPlan(action.payload, action.campaign)
          .pipe(
            map((response) => {
              const badDebtMetric: IBadDebtMetric = {
                invoiceNumber: '',
                eventCode: EventCode.paymentReview,
                templateId: '',
                userType: 'Guest',
                reasonCode: response.reasonCode,
                alertMsg: 'Logging response from EPS ',
              };
              this.metricStore.dispatch(
                MetricActions.logBadDebtMetric({ metrics: badDebtMetric })
              );
              if (response.reasonCode === '408') {
                this.store.dispatch(
                  invoiceActions.getPaymentAuthorizationTimeout({
                    payload: true,
                  })
                );
              }
              return invoiceActions.getPaymentPlanAuthorizationSuccess({
                payload: response,
              });
            }),
            catchError((errors) => {
              try {
                return of(
                  invoiceActions.getPaymentAuthorizationFailure({
                    errors: errors.error as IValidationErrors,
                  })
                );
              } catch (exception) {
                invoiceActions.clearAllSelectedPaymentsAndInvoices();
                return of(
                  invoiceActions.getPaymentAuthorizationFailure({
                    errors: undefined,
                  })
                );
              }
            })
          )
      )
    )
  );

  getPaymentAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getPaymentAuthorization),
      exhaustMap((action) =>
        this.invoiceService
          .authorizePayment(action.payload, action.campaign)
          .pipe(
            map((response) => {
              const badDebtMetric: IBadDebtMetric = {
                invoiceNumber: '',
                eventCode: EventCode.paymentReview,
                templateId: '',
                userType: 'Guest',
                reasonCode: response.reasonCode,
                alertMsg: 'Logging response from EPS ',
              };
              this.metricStore.dispatch(
                MetricActions.logBadDebtMetric({ metrics: badDebtMetric })
              );
              if (response.reasonCode === '408') {
                this.store.dispatch(
                  invoiceActions.getPaymentAuthorizationTimeout({
                    payload: true,
                  })
                );
              }
              return invoiceActions.getPaymentAuthorizationSuccess({
                payload: response,
              });
            }),
            catchError((errors) => {
              try {
                return of(
                  invoiceActions.getPaymentAuthorizationFailure({
                    errors: errors.error as IValidationErrors,
                  })
                );
              } catch (exception) {
                invoiceActions.clearAllSelectedPaymentsAndInvoices();
                return of(
                  invoiceActions.getPaymentAuthorizationFailure({
                    errors: undefined,
                  })
                );
              }
            })
          )
      )
    )
  );

  getPaymentAuthorizationReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getPaymentAuthorizationReceipt),
      exhaustMap((action) =>
        this.invoiceService.getPdfDetails(action.payload.transactionId).pipe(
          map(() => invoiceActions.getPaymentAuthorizationReceiptSuccess()),
          catchError(() =>
            of(invoiceActions.getPaymentAuthorizationReceiptFailure())
          )
        )
      )
    )
  );

  getApplePaySupportFlag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.getApplePaySupportFlag),
      exhaustMap((_action) =>
        this.invoiceService.getApplePaySupportFlag().pipe(
          map((response) =>
            invoiceActions.getApplePaySupportFlagSuccess({ response })
          ),
          catchError((error: IRequestError) =>
            of(invoiceActions.getApplePaySupportFlagFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private invoiceService: InvoiceService,
    private store: Store<InvoiceState>,
    private metricStore: Store<MetricState>
  ) {}
}
